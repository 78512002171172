import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';

import { Container, Image, Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';

import useLocale from 'hooks/useLocale';

import { BodyCard, EngineCard } from 'components/Cards';
import RewindButton from 'components/RewindButton';
import BottomBar from 'components/BottomBar';
import LoadingSpinner from 'components/LoadingSpinner';
import { setBuilderData, setRawBuilderData } from 'store/entities/data';
import { setConfig } from 'store/entities/config';
import LocalizedLink from 'components/LocalizedLink';
import { TechDataModal } from 'components/modals';
import Price from 'components/Price';

//import { setBody, setEngine, setSpecification } from 'store/entities/config';
import PlaceholderCar from 'assets/images/placeholder-car.png';
import { ReactComponent as ArrowLeft } from 'assets/images/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg';

const CarPartSelection = () => { //const state = useSelector(state => state); console.log('state', state);
  const { t } = useTranslation();
  const locale = useLocale();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('equipment');

  const { model, specification, body } = useSelector(state => state.config);
  const { builderData, bodyData } = useSelector(state => state.data);

  const ENV = window.ENV[window.location.hostname] || window.ENV.default;

  const image = builderData.pictures?.main || builderData.exteriors?.[0]?.pictures?.main || PlaceholderCar;

  const handleModal = type => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const { isFetching, refetch: fetchData } = useQuery(
    'builder-data',
    async () =>
      axios.get(`/builder/combined/${specification}`, {
        params: {
          brand: ENV.BRAND_NAME,
          language: locale
        },
      }),
    {
      enabled: false,
      onSuccess: res => {
        dispatch(setBuilderData(res.data));
        dispatch(setRawBuilderData(res.data));
        dispatch(setConfig(res.data.default));
      },
    }
  );

  useEffect(() => {
    (model && specification) ? fetchData() : window.location.replace(ENV.FALLBACK_URL);
  }, [model, specification, fetchData, ENV]);

  //const { refetch: fetchLatvianBuilderData } = useQuery(
  //  'builder-data-latvian',
  //  async () => {
  //    const res = await axios.get(`/builder/combined/${specification}`, {
  //      params: { language: `lv_LV` },
  //    });
  //    return res;
  //  },
  //  {
  //    enabled: false,
  //    onSuccess: res => {
  //      dispatch(setRawBuilderData(res.data));
  //    },
  //  }
  //);

  //useEffect(() => {
  //  if (model && specification) {
  //    fetchData();
  //    //fetchLatvianBuilderData();
  //  } else {
  //    window.location.replace(process.env.REACT_APP_FALLBACK_URL);
  //  }
  //}, [model, specification, fetchData, /*fetchLatvianBuilderData*/]);

  //useEffect(() => {
  //  const bodyKeys = Object.keys(bodyData);
  //  if (!body && bodyKeys.length) {
  //    dispatch(setBody(bodyData[bodyKeys[0]].id));
  //  }

  //  if (body && bodyKeys.length && !engine) {
  //    dispatch(setEngine(bodyData[body].engines[0].id || null));
  //  }
  //}, [body, bodyData, dispatch, engine]);

  return (
    <>
      {isFetching && <LoadingSpinner />}
      <section className="c-section">
        <div className="c-vehicle">
          <Container className="c-vehicle__body">
            <div className="c-vehicle__caption c-caption">
              <h2 className="c-caption__title">{builderData.name}</h2>
              <p className="c-caption__price c-price">
                <span className="c-price__title">{t('price')}</span>
                <strong className="c-price__value"><Price price={builderData.price} /></strong>
              </p>
              <p className="c-caption__btn">
                <RewindButton href={`/grade?id=${model}`} text={t('switch_vehicle')} />
              </p>
            </div>
            <div className="c-vehicle__info c-info">
              <h5 className="c-info__title">{t('equipment')}</h5>
              <ul className="c-info__list">
                {builderData.equipment?.slice(0, 4).map(item => (
                  <li className="c-info__item" key={item.id}>{item.name}</li>
                ))}
              </ul>
              <p className="c-info__btn">
                <Button variant="light" onClick={() => handleModal('equipment')}>
                  {t('view_more_btn')}
                </Button>
              </p>
            </div>
          </Container>
          <figure className="c-vehicle__img">
            <Image src={image} />
          </figure>
        </div>
      </section>
      <section className="c-section c-engines">
        <Container>
          {Object.keys(bodyData).length > 1 && (
            <>
              <h4 className="c-section__subtitle">{t('choose_body')}</h4>
              <Swiper
                className="card-swiper carpart-swiper"
                cssMode={true}
                slidesPerView={'auto'}
                navigation={{
                  prevEl: '.swiper-button-prev',
                  nextEl: '.swiper-button-next',
                }}
                pagination={{
                  clickable: true,
                }}
                mousewheel={true}
                keyboard={true}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              >
                {Object.keys(bodyData).map(key => (
                    <SwiperSlide key={bodyData[key].id}>
                      <BodyCard bodyData={bodyData[key]} handleInfo={() => handleModal('techdata')} />
                    </SwiperSlide>
                ))}
                <button className="swiper-button swiper-button-prev" type="button"><ArrowLeft /></button>
                <button className="swiper-button swiper-button-next" type="button"><ArrowRight /></button>
              </Swiper>
            </>
          )}
          <h4 className="c-section__subtitle">{t('choose_engine')} </h4>
          <Swiper
            className="card-swiper carpart-swiper"
            cssMode={true}
            slidesPerView={'auto'}
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            pagination={{
              clickable: true,
            }}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          >
            {bodyData[body]?.engines.map((engine, idx) => (
              <SwiperSlide key={`${engine.id}-${idx}`}>
                <EngineCard engineData={engine} handleInfo={() => handleModal('techdata')} />
              </SwiperSlide>
            ))}
            <button className="swiper-button swiper-button-prev" type="button"><ArrowLeft /></button>
            <button className="swiper-button swiper-button-next" type="button"><ArrowRight /></button>
          </Swiper>
        </Container>
      </section>
      <BottomBar action={
        <LocalizedLink to="/assemble" className="btn btn-link">
          {t('continue_button')}
          <ArrowRight className="btn-ico btn-ico-right" />
        </LocalizedLink>
      }/>
      <TechDataModal show={isModalOpen} onClose={() => setIsModalOpen(false)} type={modalType} />
    </>
  );
};

export default CarPartSelection;
