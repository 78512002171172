import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import axios from 'axios';
import useSalesFrontConfig from 'hooks/useSalesFrontConfig';
import useSelectedAccessory from 'hooks/useSelectedAccessory';

const OfferForm = ({ handleStepChange }) => {
  const { t } = useTranslation();
  const salesfrontConfig = useSalesFrontConfig();
  const { gradeId } = useSelector(state => state.config);
  const { locations, offerModalType } = useSelector(state => state.app);
  const { builderData } = useSelector(state => state.data);
  const { register, handleSubmit, watch, setValue } = useFormContext();
  const { engine, body } = useSelectedAccessory();
  const [ consentsList, setConsentsList ] = useState([]);

  const { model: builderModel, grades } = builderData;

  const location = watch('location');

  const { mutate } = useMutation('offer-form', async data => axios.post(`/customers/lead`, data), {
    onSuccess: () => handleStepChange('finish'),
  });

  const { refetch: fetchData } = useQuery( // fetch consrnts data
    'consent-data',
    async () => {
      const res = await axios.get(`/consent`, {
        params: {
          limit: 100,
          isActive: true,
        },
      });
      return res.data;
    },
    {
      enabled: false,
      onSuccess: res => {
        setConsentsList(res.filter(item =>
          [
            'configurator consent email',
            'configurator consent phone',
            'configurator consent sms'
          ].some(el =>
            item.title.toLowerCase().includes(el)
          )
        ));
      },
    }
  );

  useEffect(() => {
    if (location === '1' && !!locations.length) {
      setValue('location', locations[0].shortName);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createTestDriveDescription = () =>
    `Test drive, "${builderModel.name}", "${
      grades?.find(item => item.id === gradeId)?.name || ''
    }", "${engine.name}", "${body.name}"`;

  const createOfferDescription = () => `${builderData?.name} - request for offer`;

  const handleFormSubmit = data => {
    const { firstName, lastName, email, /*phoneCode,*/ phoneNr, location, consents } = data;

    const payload = {
      location,
      source: window.location.href,
      description:
        offerModalType === 'offer' ? createOfferDescription() : createTestDriveDescription(),
      customer: {
        firstName,
        lastName,
        //phoneCode,
        phoneNumber: phoneNr,
        email,
      },
      config: salesfrontConfig,
      createActivity: offerModalType === 'offer',
      consents: consents.map(item => ({consent: item, isSigned: true})),
    };
    handleStepChange('loading');
    mutate(payload);
  };

  return (
    <form onSubmit={handleSubmit(data => handleFormSubmit(data))}>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="firstName" className="required">
          {t('form_first_name')}
        </Form.Label>
        <Form.Control
          id="firstName"
          name="firstName"
          {...register('firstName', { required: true })}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="lastName" className="required">
          {t('form_last_name')}
        </Form.Label>
        <Form.Control id="lastName" name="lastName" {...register('lastName', { required: true })} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="lastName" className="required">
          {t('form_email')}
        </Form.Label>
        <Form.Control
          type="email"
          id="email"
          name="email"
          {...register('email', { required: true })}
        />
      </Form.Group>
      {/*<Form.Group className="mb-3">
        <Form.Label htmlFor="phoneCode" className="required">
          {t('form_phone_code')}
        </Form.Label>
        <Form.Control
          id="phoneCode"
          name="phoneCode"
          {...register('phoneCode', { required: true })}
        />
      </Form.Group>*/}
      <Form.Group className="mb-3">
        <Form.Label htmlFor="phoneNr" className="required">
          {t('form_phone_number')}
        </Form.Label>
        <Form.Control
          type="phone"
          id="phoneNr"
          name="phoneNr"
          {...register('phoneNr', { required: true })}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="location">{t('form_location')}</Form.Label>
        <Form.Select {...register('location')}>
          {locations.map(item => (
            <option key={item.shortName} value={item.shortName}>
              {item.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      {!!consentsList.length && <Form.Group className="mb-3">
        <Form.Label>{t('consent_marketing_text')}</Form.Label>
        {consentsList.map(item => {
          let label = item.title;
          if (label.toLowerCase().includes('email')) label = t('form_email');
          if (label.toLowerCase().includes('sms')) label = t('form_sms');
          if (label.toLowerCase().includes('phone')) label = t('form_phone_number');
          return <Form.Check
            key={item.iri}
            inline
            type="checkbox"
            name="consents[]"
            value={item.iri}
            label={label}
            {...register('consents')}
          />
        })}
      </Form.Group>}
      <Form.Group className="mb-3">
        <Form.Text>
          {t('disclaimer_legal_text')}<br/>
          <a href={t('disclaimer_legal_url')} target="_blank">{t('disclaimer_legal_more')}</a>
        </Form.Text>
      </Form.Group>
      <Button type="submit">{t('submit_button')}</Button>
    </form>
  );
};

export default OfferForm;
