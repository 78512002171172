import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';
//import { useNavigate } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Button, Container, Image } from 'react-bootstrap';

import Packages from 'components/Packages';
import AssemblySliderSelectors from 'components/AssemblySliderSelectors';
import ThreeSixtyWrapper from 'components/360Viewer/ThreeSixtyWrapper';
import LocalizedLink from 'components/LocalizedLink';
import RewindButton from 'components/RewindButton';
import BottomBar from 'components/BottomBar';
import { PackagesModal } from 'components/modals';
import LoadingSpinner from 'components/LoadingSpinner';
import Price from 'components/Price';

import { getActiveInteriorPicture } from 'utils/dataUtils';
import { setColor, setInterior, setWheels, updateAccessories, updateExtraPackages } from 'store/entities/config';
import { setCompatibilityData } from 'store/entities/data';

import useLocale from 'hooks/useLocale';

import { ReactComponent as FullscreenIco } from 'assets/images/icons/Fullscreen.svg';
import { ReactComponent as CloseFullscreenIco } from 'assets/images/icons/ExitFullscreen.svg';
import { ReactComponent as ArrowRight } from 'assets/images/icons/ArrowRight.svg';

import Accessories from 'components/Accessories';
//import InteriorImage from 'views/Assemble/InteriorImage';
//import useSelectedAccessory from 'hooks/useSelectedAccessory';
import useBreakPoints from 'hooks/useBreakPoints';

const Assemble = () => { //const state = useSelector(state => state); console.log('state', state);
  const { t } = useTranslation();
  const locale = useLocale();
  const dispatch = useDispatch();

  const [option, setOption] = useState(null);
  const [resolve, setResolve] = useState(null);

  const { model, specification, colorCode, interior, wheels, extraPackages, accessories } = useSelector(state => state.config);
  const { builderData } = useSelector(state => state.data);
  const { selectedTab } = useSelector(state => state.app);

  const ENV = window.ENV[window.location.hostname] || window.ENV.default;

  const handle = useFullScreenHandle();
  const { isSmallerThanLg } = useBreakPoints();
  //const navigate = useNavigate();
  //const { engine, body } = useSelectedAccessory();

  const packagesData = builderData?.options || [];
  const selectedPackages = packagesData.filter(item => extraPackages.includes(item.id)).map(item => item.code);

  const accessoriesData = builderData?.accessories || {};
  const allAccessories = Object.keys(accessoriesData).reduce((result, key) => result.concat(accessoriesData[key]), []);
  const selectedAccessories = allAccessories.filter(item => accessories.includes(item.id)).map(item => item.code);

  if (!model && !specification) window.location.replace(ENV.FALLBACK_URL);

  const resolveConfirm = () => {
    let resolvedPackages = [...extraPackages];
    let resolvedAccessories = [...accessories];
    resolve.incompatibleOptions?.forEach(set => // remove conflict options
      set.forEach(item => item.hasOwnProperty('fittingTime') ?
        resolvedAccessories = resolvedAccessories.filter(id => id !== item.id) :
        resolvedPackages = resolvedPackages.filter(id => id !== item.id)
      )
    );
    resolve.requiredOptions?.forEach(set => // add additional options
      set.forEach(item => item.hasOwnProperty('fittingTime') ?
        resolvedAccessories.push(item.id) :
        resolvedPackages.push(item.id)
      )
    );
    switch (option.type) {
      case 'options':
        option.check ?
          resolvedPackages = resolvedPackages.filter(id => id !== resolve.option.id) :
          resolvedPackages.push(resolve.option.id);
        break;
      case 'accessories':
        option.check ?
          resolvedAccessories = resolvedAccessories.filter(id => id !== resolve.option.id) :
          resolvedAccessories.push(resolve.option.id);
        break;
      case 'exteriors':
        dispatch(setColor(resolve.option.code));
        break;
      case 'wheels':
        dispatch(setWheels(resolve.option.code));
        break;
      case 'interiors':
        dispatch(setInterior(resolve.option.code));
        break;
    }
    dispatch(updateAccessories(resolvedAccessories));
    dispatch(updateExtraPackages(resolvedPackages));
    dispatch(setCompatibilityData(resolve));
    setOption(null);
    setResolve(null);
  };

  const resolveCancel = () => {
    setOption(null);
    setResolve(null);
  };

  const { isFetching, refetch: fetchCompatibility } = useQuery( // fetch data with updated packages
    'compatibility-data',
    async () => {
      const res = await axios.get(`/builder/specifications/${specification}/compatibility`, {
        params: {
          brand: ENV.BRAND_NAME,
          [option.check ? 'remove' : 'add']: option.code,
          'options.code': [
            ...selectedPackages,
            ...selectedAccessories,
            colorCode,
            interior,
            wheels
          ].filter(item => item),
          language: locale,
        },
      });
      return res.data;
    },
    {
      enabled: false,
      onSuccess: res => {
        if (res.requiredOptions || res.incompatibleOptions) res.resolve = true; // need confirmation for resolving
        setResolve(res);
      },
    }
  );

  useEffect(() => {
    if (resolve && !resolve.resolve) resolveConfirm();
  }, [resolve]);

  useEffect(() => {
    if (option) fetchCompatibility();
  }, [option, fetchCompatibility]);

  return (
    model && specification && (
      <>
        {isFetching && <LoadingSpinner />}
        <section className={`c-section c-threesixty${selectedTab === 'interiors' ? ' c-threesixty--interior' : ''}`}>
          <FullScreen handle={handle} className="c-threesixty__vehicle c-vehicle">
            <Container className="c-vehicle__body">
              <div className="c-vehicle__caption c-caption">
                <h2 className="c-caption__title">{builderData.model.name}</h2>
                <p className="c-caption__descr">{builderData.name}</p>
                <p className="c-caption__price c-price">
                  <span className="c-price__title">{t('price')}</span>
                  <strong className="c-price__value"><Price price={builderData.price} /></strong>
                </p>
                <p className="c-caption__btn">
                  <RewindButton href={`/grade?id=${model}`} text={t('switch_vehicle')} />
                </p>
              </div>
              {!isSmallerThanLg && (
                <p className="c-vehicle__action">
                  {handle.active ?
                  <Button onClick={handle.exit} variant="link">
                    {t('close_button')}
                    <CloseFullscreenIco className="btn-ico btn-ico-right" />
                  </Button>
                  :
                  <Button onClick={handle.enter} variant="link">
                    {t('full_view')}
                    <FullscreenIco className="btn-ico btn-ico-right" />
                  </Button>}
                </p>
              )}
            </Container>
            {selectedTab === 'interiors' ? (
              <figure className="c-vehicle__img">
                <Image src={getActiveInteriorPicture(builderData.interiors, interior)} />
              </figure>
            ) : (
              <ThreeSixtyWrapper />
            )}
          </FullScreen>
          <div className="c-threesixty__nav">
            <Container>
              <AssemblySliderSelectors onChange={setOption} />
            </Container>
          </div>
        </section>
        <section className="c-section">
          <Container>
            <Packages onChange={setOption} />
            <Accessories onChange={setOption} />
          </Container>
        </section>
        <BottomBar action={
          <LocalizedLink to="/conclusion" className="btn btn-link">
            {t('summary_button')}
            <ArrowRight className="btn-ico btn-ico-right" />
          </LocalizedLink>
        }/>
        <PackagesModal
          show={resolve?.resolve}
          data={resolve}
          target={option}
          onConfirm={resolveConfirm}
          onCancel={resolveCancel}
        />
      </>
    )
  );
};

export default Assemble;
