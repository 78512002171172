import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Accordion, ListGroup, Button, Badge } from 'react-bootstrap';

import useAccessoriesPrice from 'hooks/useAccessoriesPrice';

import Price from 'components/Price';

const SelectedAccessories = () => {
    const { t } = useTranslation();

    const { accessories } = useSelector(state => state.config);
    const { builderData } = useSelector(state => state.data);

    const accessoriesPrice = useAccessoriesPrice();

    if (!accessories.length) return null;

    const accessoriesData = builderData?.accessories || {};
    const accessoriesSelected = [].concat(
        ...Object.keys(accessoriesData).map(key => accessoriesData[key])
    ).filter(option =>
        accessories.includes(option.id)
    );

    return (
        <Accordion flush className="c-accordion">
            <Accordion.Item eventKey="accordionPackages" className="c-accordion__item">
                <Accordion.Button className="c-accordion__toggler">
                    <span className="c-accordion__title">{t('selected_accessories')}</span>
                    <Badge pill bg="dark" className="c-accordion__count">{accessories.length}</Badge>
                    <span className="c-accordion__price"><Price price={accessoriesPrice} /></span>
                </Accordion.Button>
                <Accordion.Body className="c-accordion__body">
                    <ListGroup variant="flush">
                        {accessoriesSelected.map(item => 
                            <ListGroup.Item key={item.code}>
                                <span className="list-group-label">
                                    {item.name}
                                </span>
                                <b className="list-group-value"><Price price={item.price} placeholder="&ndash;" /></b>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
};

export default SelectedAccessories;
