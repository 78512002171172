import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, ListGroup, Stack } from 'react-bootstrap';

import useBreakPoints from 'hooks/useBreakPoints';

import { AccessoryItem } from './AccessoryItem';

const AccessoriesCategory = ({ category, data, isConclusion = false, onChange }) => {
    const { t } = useTranslation();

    const { accessories } = useSelector(state => state.config);
    const { searchValue } = useSelector(state => state.app);

    const [isShowingAll, setIsShowingAll] = useState(false);
    const { isSmallerThanMd, isSmallerThanXl } = useBreakPoints();

    const isItemSelected = useCallback(id =>
        !!accessories.filter(item => item === id).length,
        [accessories]
    );

    const filteredData = useMemo(() =>
        data.filter(item => isConclusion ?
            isItemSelected(item.id) :
            item.name.toLowerCase().includes(searchValue.toLowerCase()),
        [data, isConclusion, isItemSelected, searchValue]
    ));

    const renderItem = () => filteredData.filter((item, index) =>
        isConclusion ? isItemSelected(item.id) : isShowingAll || index < showingLimit)
        .map(item => {
            const isChecked = isItemSelected(item.id);
            const option = { code: item.code, check: isChecked, type: 'accessories' };
            return (
                <ListGroup.Item
                    key={item.id}
                    active={!isConclusion ? isChecked : false}
                    onClick={!isConclusion ? () => onChange(option) : null}
                    className="c-accessory-list__item"
                >
                    <AccessoryItem data={item} />
                </ListGroup.Item>
            )
        });

    const filteredCount = filteredData.length;
    const showingLimit = isSmallerThanMd ? 2 : isSmallerThanXl ? 3 : 5;
    const isShowMore = !isConclusion && !isShowingAll && filteredCount > showingLimit;

    return !filteredCount ? null : (
        <Stack className="c-category">
            <Stack className="c-category__header">
                <h4 className="c-category__title">{category}</h4>
                <span className="c-category__count">{t('category_units_no', { count: filteredCount })}</span>
            </Stack>
            <ListGroup className={`c-accessory-list${isConclusion ? ' c-accessory-list--cart' : ''}`}>
                {renderItem()}
            </ListGroup>
            {isShowMore && (
                <Stack className="c-category__more">
                    <Button variant="light" onClick={() => setIsShowingAll(true)}>
                        {t('category_units_one_more', { category })}
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};

export default AccessoriesCategory;
